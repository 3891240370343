

import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Spinner, Modal } from "react-bootstrap";
import TokenStore from '../stores/token';
import { Tweet } from "../models/tweet";
import TweetComponent from "../components/Tweet";
import CreatePost from "../components/CreatePost";
import { ALERTS } from "../constants";
import { PostActions } from "../actions/post";

export default class TweetList extends React.Component {
  props: {
    showError?: (message: string, type?: string) => void
    hasMore?: boolean;
    loadLatest?: () => Promise<void>;
    loadOlder?: () => Promise<void>;
  };

  state = {
    postToDel: '',
    showDelModal: false,
    deleting: false,

    token: TokenStore.getActive(),
  }

  componentDidMount() {
    TokenStore.addChangeListener(this.onTokenChange);
  }

  componentWillUnmount() {
    TokenStore.removeChangeListener(this.onTokenChange);
  }

  render() {
    // Load the active twitter user
    let activeToken = this.state.token;

    if (!activeToken || !activeToken.account) {
      return (
        <div className="timeline-loader">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )
    }

    let pendingPosts = this.props.pendingPosts.map(post => {
      // Convert post to tweet
      let tweet = Tweet.fromPost(post, activeToken.account);

      return (
        <TweetComponent
          key={post.id}
          data={tweet}
          isPost={true}
          editTweet={this.editPost}
          deleteTweet={this.confirmPostDelete}
          {...this.props}
        />
      );
    });

    let tweets = this.props.tweets.map(tweet => {
      return (
        <TweetComponent
          key={tweet.id_str}
          data={tweet}
          editTweet={this.editPost}
          deleteTweet={this.confirmPostDelete}
          {...this.props}
        />
      );
    });

    return (
      <div>
        <div> 
          <CreatePost 
            editId={this.state.postToEdit}
            cancelEdit={this.cancelEditPost}/>
        </div>

        <div className="mt-4 timeline">
          <div className="refresh-btn">
            <Button onClick={this.props.loadLatest}>Load Latest</Button>
          </div>

          {pendingPosts}

          <InfiniteScroll
            dataLength={tweets.length} //This is important field to render the next data
            next={this.props.loadOlder}
            hasMore={this.props.hasMore}
            scrollThreshold="100px"
            loader={
              <div className="timeline-loader">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Nothing else to see here!</b>
              </p>
            }
            // below props only if you need pull down functionality
            refreshFunction={this.props.loadLatest}
            pullDownToRefresh
            pullDownToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8595; Pull down to refresh
              </h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: "center" }}>
                &#8593; Release to refresh
              </h3>
            }
          >
            {tweets}
          </InfiniteScroll>
        </div>

        {this.renderDeleteModal()}
      </div>
    );
  }

  renderDeleteModal() {
    return (
      <Modal show={this.state.showDelModal} onHide={this.closeDeleteModel}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>This will permanently delete your tweet from Twitter. Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeDeleteModel}>
            Cancel
          </Button>
          <Button 
            variant="danger" 
            onClick={this.delPost}
            disabled={this.state.deleting}>
            Delete Forever
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /**
   * Confirm delete post stuff
   */
  confirmPostDelete = (id: string) => {
    this.setState({
      postToDel: id,
      showDelModal: true
    });
  };

  closeDeleteModel = () => {
    this.setState({
      postToDel: "",
      showDelModal: false
    });
  };

  /**
   * Edit post
   */
  editPost = (id: string) => {
    this.setState({postToEdit: id});

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   * Cancel edit post
   */
  cancelEditPost = () => {
    this.setState({postToEdit: ''});
  }

  delPost = () => {
    this.setState({deleting: true})

    PostActions.deletePost(this.state.postToDel)
    .then(res => {
      this.setState({
        postToDel: "",
        showDelModal: false,
        deleting: false
      });
    })
    .catch(err => {
      this.props.showError(ALERTS.postDeleteErr);
    });
  };

  onTokenChange = ()=> {
    this.setState({token: TokenStore.getActive() });
  }
}
