import React from "react";
import { Button,Form, Alert, Image } from 'react-bootstrap';
import twitterText from 'twitter-text'
import { PostActions } from '../actions/post';
import { TimelineActions } from '../actions/timeline';
import TokenStore from '../stores/token';
import { ALERTS } from '../constants';

const DEFAULT_PROMPT = "What's happening?";
const SHOW_EDIT_HELP = 'showEditHelp';

export default class CreatePost extends React.Component  {

  state = {
    editId: '', // if in editting mode
    value: '',
    activeAccount: TokenStore.getActive(),

    submitting: false,
    cancelling: false,
    showError: false,
    showEditHelp: false, // show edit info alert
  }

  componentDidMount() {
    TokenStore.addChangeListener(this.onTokenChange);
  }

  componentWillUnmount() {
    TokenStore.removeChangeListener(this.onTokenChange);
  }

  componentDidUpdate(prevProps, prevState) {

    // If in edting mode
    if (this.state.editId && prevState.editId !== this.state.editId) {

      /**
       * If post is pending, stop the countdown
       */
      let post = TimelineActions.getPostForEdit(this.state.editId);
      if (post.status === 'pending') {
        PostActions.startEdit(this.state.editId, false)
      }

      // Check if user has seen the edit info alert
      let showEditHelp = window.localStorage.getItem(SHOW_EDIT_HELP);
      if (!showEditHelp) {
        this.setState({showEditHelp: true});
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    // Start edit
    if (nextProps.editId && nextProps.editId !== prevState.editId) {

      // Get post to edit
      let post = TimelineActions.getPostForEdit(nextProps.editId)
      return { editId: nextProps.editId, value: post.message }
    }

    // Cancel edit or edit finish
    else if (prevState.editId && nextProps.editId === '') {
      return { editId: '', value: '', showError: false, submitting: false }
    }

    return null;
  }

  render() {
    // Get active token
    let activeAccount = this.state.activeAccount

    let textPlaceholder = DEFAULT_PROMPT;
    if (activeAccount && activeAccount.settings && activeAccount.settings.customPrompt) {
      textPlaceholder = activeAccount.settings.customPrompt;
    }
    let submitting = this.state.submitting;

    // Parse tweet using twitter lib
    let parsedStatus = twitterText.parseTweet(this.state.value);

    // Don't show error if there is no text yet
    let textBoxValid = false;
    if (parsedStatus.valid || this.state.value === '') {
      textBoxValid = true;
    }

    return (
      <form onSubmit={this.handleSubmit} className="mb-3 create-post">

        {this.renderInfo()}
        {this.renderAlert()}
        {this.renderTwitterProfile()} 
        
        <Form.Group
          controlId="formBasicEmail">
          <Form.Control
            required
            as="textarea"
            isInvalid={!textBoxValid}
            className="mw-95"
            placeholder={textPlaceholder}
            value={this.state.value}
            onChange={this.handleChange.bind(this)}/>

          <Form.Text className="ml-1">
          {parsedStatus.weightedLength > 0 && parsedStatus.weightedLength}
          </Form.Text>
        </Form.Group>

        <div className="post-btns">
          {this.renderCancel()}

          <Button
            type="submit"
            value="Submit"
            variant="secondary"
            disabled={submitting || !parsedStatus.valid || this.state.cancelling}>
              {submitting ? 'Sending…' : 'Send'}
            </Button>
        </div>
      </form>
    )
  }

  renderTwitterProfile() {
    // Get active token
    let activeAccount = this.state.activeAccount;

    if (activeAccount && activeAccount.account) {
      return (
        <div className="d-flex flex-row bd-highlight mb-3">
          <Image src={activeAccount.account.profile_image_url_https} roundedCircle className="bd-highlight"/>
          <div className="p-1 my-2 text-muted align-middle">@{activeAccount.account.screen_name}</div>
        </div>
      )
    }
  }

  /**
   * Render cancel button if in edit mode
   */
  renderCancel() {
    let submitting = this.state.submitting;

    if (this.state.editId) {
      return (
        <Button
          variant="secondary"
          onClick={this.cancelEdit}
          disabled={submitting || this.state.cancelling}>
            {this.state.cancelling ? 'Cancelling…' : 'Cancel'}
          </Button>
      )
    }
  }

  /**
   * Render info
   * - Only show if not seen, and in edit mode
   */
  renderInfo() {
    let showEditHelp = false;
    if (this.state.showEditHelp && this.state.editId) {
      showEditHelp = true;
    }

    return (
      <Alert variant="info" show={showEditHelp} 
        onClose={this.onAlertClose.bind(this)} dismissible>
        <p><strong>A note about Redo</strong> We've copied your old tweet here, after you edit below, we'll delete the original tweet (you'll lose any replies, likes or retweets) and post this new one.</p>
      </Alert>
    )
  }

  renderAlert() {
    return (
      <Alert 
        variant="danger" 
        onClose={() => this.setState({showError: false})}
        show={this.state.showError}
        dismissible>
        <Alert.Heading>{ALERTS.defaultErrorHeader}</Alert.Heading>
        <p>
          Apologies. We ran into an issue creating your post.
        </p>
      </Alert>
    )
  }

  onAlertClose = ()=> {
    this.setState({showEditHelp:false});

    window.localStorage.setItem(SHOW_EDIT_HELP, "true");
  }

  /**
   * Handle submit / send tweet
   */
  handleSubmit = (e)=> {
    if (e) {
      e.preventDefault();
    }

    // Parse tweet text
    let parsedStatus = twitterText.parseTweet(this.state.value);
    let token = TokenStore.getActive();

    // Validate
    if (this.state.submitting || !parsedStatus.valid) {
      return
    }

    if (!token) {
      return this.setState({
        submitting: false,
        showError: true,
        errorText: 'You need to connect Twitter first.'
      });
    }

    this.setState({ submitting: true, showError: false })

    let post = {
      message: this.state.value,
      tokenId: token.id,
    }

    // If in edting mode
    if (this.state.editId) {

      PostActions.endEdit(this.state.editId, post)
      .then((result) => {

        // Switch out of edit mode
        this.props.cancelEdit();
      })
      .catch((err) => {
        this.setState({
          submitting: false,
          showError: true,
          errorText: ALERTS.postUpdateErr
        });
      });
    }
    else {
      PostActions.createPost(post)
      .then((result) => {
        this.setState({
          value: '',
          submitting: false
        })
      })
      .catch((err) => {
        this.setState({
          submitting: false,
          showError: true
        })
      });
    }
  }

  /**
   * Cancel Edit
   */
  cancelEdit = ()=> {
    let post = TimelineActions.getPostForEdit(this.state.editId);

    // If it has already been posted, do nothing
    // Clear editing state...
    if (post.status === 'posted') {
      this.props.cancelEdit();
    }

    // Else it was pending
    else {
      this.setState({
        value: '',
        cancelling: true
      })

      PostActions.endEdit(post.id, post)
      .then((result) => {
        this.setState({
          cancelling: false,
          showError: false,
          errorText: ''
        }, () => {
          this.props.cancelEdit();
        });
      })
      .catch((err) => {
        this.setState({
          cancelling: false,
          showError: true,
          errorText: ALERTS.postUpdateErr
        });
      });

    }
  }

  handleChange(e) {
    let val = e.target.value;
    this.setState({value: val});
  }

  loadPost() {
    let post = TimelineActions.getPostForEdit(this.props.postId)

    this.setState({value: post.message});
  }

  onTokenChange = ()=> {
    this.setState({activeAccount: TokenStore.getActive() });
  }
}