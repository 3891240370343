import React from "react";

// import { Elements, StripeProvider } from "react-stripe-elements";
import {
  Col,
  Row,
  Container,
  Button,
  Form,
} from "react-bootstrap";
import classnames from 'classnames';
// import CheckoutForm from "../components/CheckoutForm";
// import config from "../config";
import { UserActions } from "../actions/user";
import UserStore from "../stores/user";
import { ALERTS, ALERT_TYPES } from '../constants';

export default class Checkout extends React.Component {

  props: {
    showError?: (message: string, type?: string)=>void
  }

  state = {
    user: UserStore.get(),
    selectedPlan: "",
    plans: [],

    _cancelling: false
  };

  componentDidMount() {
    UserStore.addChangeListener(this.onUserChange);

    this.loadPlans();
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.onUserChange);
  }

  render() {
    // let selectedPlan = this.state.plans.find(plan => plan.id === this.state.selectedPlan);

    return (
      <Container fluid className="main">
        <Row className="pt-4">
          <Col xs={0} md={2}></Col>
          <Col xs={12} md={8}>
          <h3 className="block-heading mb-4">Your plan</h3>
          <p>Your current plan is highlighted.</p>

          {this.renderPlans()}
          <h3>Your Payment Method</h3>
          {this.renderSources()}

            {/* <div className="checkout">
              <div className="credit-card">
                <StripeProvider apiKey={config.stripe.key}>
                  <div>
                    <Elements>
                      <CheckoutForm
                        selectedPlan={selectedPlan}
                        {...this.props}/>
                    </Elements>
                  </div>
                </StripeProvider>
              </div>
              </div> */}
          <h3 className="block-heading mb-4">Account changes</h3>
          <p>If you want to cancel your account, you can do so below. If you have questions or need to contact us about the features included in your account please visit <a href="/support">Brizzly Support</a>.</p>
          <div className="text-center mb-4">
            <Button 
              variant='danger'
              onClick={this.cancelAccount}
              disabled={this.state._cancelling}>Cancel Account</Button>
        </div>
          </Col>
          <Col xs={0} md={2}></Col>
        </Row>
      </Container>
    );
  }

  renderSources() {
    let user = UserStore.get();
    let sources = user.customer.sources;

    if (!sources || sources.data.length === 0) {
      return
    }

    let _sources = sources.data.map( (source: any) => {
      if (!source.brand) {
        return
      }

      let classes = classnames('col-12', 'col-lg-4', 'card mb-4', 'shadow-sm', {'active': false} );

      return (
        <div className={classes} 
        key={source.id}>
        <div className="card-body">
          <h5 className="card-title"><div>{source.brand}: {source.last4}</div></h5>
          <p>Expires: {source.exp_month}/{source.exp_year}</p>

          {/* <Button onClick={this.removeCard.bind(this, source.id)}>Remove Card</Button> */}
        </div>
      </div>
      )
    })

    return (
      <div className="card-deck mb-3 text-left">
        {_sources}
      </div>
    );
  }

  cancelAccount = ()=> {

    this.setState({_cancelling: true});

    UserActions.cancel()
    .then(() => {

      // TODO: Add slight delay before redirect?
      window.location.href = 'http://blog.brizzly.com/bye';
    })
    .catch((err) => {
      this.props.showError(ALERTS.defaultOops)
    })
    .finally(()=> {
      this.setState({_cancelling: false});
    }) 
  }

  removeCard(id: string) {
    UserActions.removeCard(id)
    .catch(err => {
      this.props.showError(ALERTS.defaultOops)
    });
  }

  renderAmount(amount: number) {
    return `$${amount / 100}`;
  }

  renderPlans() {
    let plans = this.state.plans
    .sort((a, b)=> {
      return a.order - b.order;
    })
    .map(plan => {
      let classes = classnames('col-12', 'col-lg-4', 'card mb-4', 'shadow-sm', 'suggested-plan', {'active': this.state.selectedPlan === plan.id} );

      return (
        <div className={classes} 
          key={plan.id} >
          <div className="card-body">
            <h5 className="card-title">{plan.name}</h5>
            <p>{plan.description}</p>
            <p className="text-muted">{this.renderAmount(plan.amount)} {plan.terms}</p>
          </div>
        </div>
      );
    });

    return (
      <div className="card-deck mb-3 text-left">
        {plans}
      </div>
    );
  }

  handlePlan = id => {
    this.setState({ selectedPlan: id });
  };

  onUserChange = () => {
    this.setState({ user: UserStore.get() });
  };

  loadPlans() {
    UserActions.loadPlans()
      .then(result => {

        let user = UserStore.get();
        let subscription = user.customer.subscriptions.data[0];
        
        // Get plan
        let selectedPlan;
        if (subscription && subscription.plan) {
          selectedPlan = subscription.plan.id;
        }

        // Default to first plan?
        let nextState = {
          plans: result.data,
          selectedPlan: selectedPlan
        };

        this.setState(nextState);
      })
      .catch(err => {
        this.props.showError(ALERTS.plansLoadErr)
      });
  }

  changePlan = ()=> {
    
  }
}

