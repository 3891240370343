import { clone, cloneDeep } from 'lodash';
import { Tweet } from '../models/tweet';
import Store from '../core/store';
import UserStore from '../stores/user';
import TokenStore from '../stores/token';

class TweetStore extends Store {
  
  tweets: {[id: string]: Tweet};
  
  constructor() {
    super();

    this.tweets = {};
  }

  getAll() {
    return this.tweets;
  }

  getAllByCreated() {
    return Object.keys(this.tweets).map(id => {
      return cloneDeep(this.tweets[id]);
    })
    .sort( (a,b)=> {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
  }

  /**
   * Get profile tweets by created
   */
  getRecentByUser() {
    let activeAccount = TokenStore.getActive();
    let tweets = [];

    Object.keys(this.tweets).forEach(id => {

      if (activeAccount && activeAccount.account && this.tweets[id].user.id_str === activeAccount.account.id_str) {
        tweets.push(this.tweets[id]);
      }
      return this.tweets[id];
    })

    return tweets
    .sort( (a,b)=> {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
  }

  getById(id: string) {
    return this.tweets[id] || null;
  }

  /**
   * Get by post id
   */
  getByPostId(postId: string) {
    for (const id in this.tweets) {
      if (this.tweets.hasOwnProperty(id)) {
        if (this.tweets[id].post && this.tweets[id].post.id === postId) {
          return this.tweets[id].post;
        }
      }
    }

    return null;
  } 

  add(tweet: Tweet) {

    // Add new tweet to top
    this.tweets[tweet.id] = tweet;

    this.emitChange();
  }

  del(id: string) {
    delete this.tweets[id];

    this.emitChange();
  }

  /**
   * Delete by post id
   */
  delByPostId(postId: string) {
    for (const id in this.tweets) {
      if (this.tweets.hasOwnProperty(id)) {
        if (this.tweets[id].post && this.tweets[id].post.id === postId) {
          delete this.tweets[id];
        }
      }
    }

    this.emitChange();
  }

  load(data: Array<Tweet>) {
    data.forEach(item => {
      this.tweets[item.id] = item;
    });

    this.emitChange();
  }
}

export default new TweetStore();