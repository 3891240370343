import { Status, FullUser, Entities, ExtendedEntities} from 'twitter-d';
import { Post } from './post';

export class Tweet {
  // contributors?: null;
  // coordinates?: Coordinates | null;
  created_at: string;
  // current_user_retweet?: TrimmedUser;
  // display_text_range?: [number, number] | null;
  entities: Entities;
  extended_entities?: ExtendedEntities | null;
  favorite_count: number;
  favorited: boolean;
  full_text: string;
  id_str: string;
  id: number;
  // in_reply_to_screen_name?: string | null;
  // in_reply_to_status_id_str?: string | null;
  // in_reply_to_status_id?: number | null;
  // in_reply_to_user_id_str?: string | null;
  // in_reply_to_user_id?: number | null;
  is_quote_status: boolean;
  // lang?: string | null;
  // place?: Place | null;
  // possibly_sensitive?: boolean | null;
  // quoted_status_id_str?: string | null;
  // quoted_status_id?: number | null;
  // quoted_status_permalink?: QuotedStatusPermalink | null;
  quoted_status?: Status | null;
  retweet_count: number;
  retweeted_status?: Tweet | null;
  retweeted: boolean;
  // scopes?: Scope | null;
  // source: string;
  truncated: boolean;
  user: FullUser;
  // withheld_copyright?: boolean | null;
  // withheld_in_countries?: string[] | null;
  // withheld_scope?: string | null;
  
  /**
   *  Brizzly added
   */
  type?: string;
  post?: Post

  constructor(params: any, type?: string) {
    params = params || {};

    this.created_at = params.created_at;
    this.entities = params.entities || {};
    this.extended_entities = params.extended_entities || null;
    this.favorite_count = params.favorite_count || 0;
    this.favorited = params.favorited || false;
    this.full_text = params.full_text;
    this.id_str = params.id_str || null;
    this.id = params.id || null;
    this.is_quote_status = params.is_quote_status || false;
    this.quoted_status = params.quoted_status || null;
    this.retweet_count = params.retweet_count || 0;
    this.retweeted_status = params.retweeted_status || null;
    this.retweeted = params.retweeted || false;
    this.truncated = params.truncated || false;
    this.user = params.user || {};

    /**
     *  Brizzly added
     */
    this.type = type || 'tweet';
    this.post = params.post || null;
  }

  /**
   * From Status
   * 
   * - Convert from Twitter status to our verion of tweet
   * 
   * @param status 
   */
  static fromStatus(status: Status) {
    let tweet = new Tweet(status, 'tweet');

    // Convert from Twitter's utc shit
    tweet.created_at = tweet.twitterStringToDate(tweet.created_at).toISOString();

    return tweet;
  }

  /**
   * Convert post to Tweet
   * 
   * - Try to map to twitter's schema
   * 
   * @param status 
   */
  static fromPost(post: Post, user: FullUser) {
    let tweet = new Tweet(post, 'post');

    tweet.created_at = post.created;
    tweet.id = null;
    tweet.full_text = post.message;
    tweet.user = user;

    /**
     *  Brizzly added
     */
    tweet.post = post;

    return tweet;
  }

  twitterStringToDate(s: string) {
    var b = s.split(/[: ]/g);
    var m = {jan:0, feb:1, mar:2, apr:3, may:4, jun:5, jul:6,
             aug:7, sep:8, oct:9, nov:10, dec:11};
  
    return new Date(Date.UTC( Number(b[7]), m[b[1].toLowerCase()], Number(b[2]), Number(b[3]), Number(b[4]), Number(b[5]) ));
  }
}