import React from "react";
import { Card, Button } from 'react-bootstrap';
import { navigate } from "@reach/router"
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRetweet, faComment, faHeart,faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { formatDistanceToNow } from 'date-fns'
import { PostActions } from '../../actions/post';
import { TimelineActions } from '../../actions/timeline';
import { Tweet } from "../../models/tweet";
import { VerifiedIcon, TwitterLogo } from '../../icons/twitter';
import Text from './Text';
import Quote from './Quote'
import Media from './Media';
import Countdown from './Countdown';
import { abbreviateNumber } from '../../utils/misc';
import { ALERTS } from '../../constants';

export default class TweetComponent extends React.Component  {

  props: {
    data: Tweet,
    isPost?: boolean;
    retweetUser?: {};
    showError?: (message: string, type?: string)=>void
  }

  render() {
    let data = this.props.data;
    return this.renderTweet(data);
  }

  renderTweet(tweet: Tweet) {
    let post = this.props.data.post;
    let retweet = tweet.retweeted_status;
    let retweetedUser;
    if (this.props.retweetUser) {
      retweetedUser = (
        <div>
          <FontAwesomeIcon icon={faRetweet} size="1x" /> {this.props.retweetUser.name} Retweeted
        </div>
      )
    }

    if (retweet) {
      return <TweetComponent data={tweet.retweeted_status} retweetUser={tweet.user} originalTweet={tweet}/>
    }

    let QuoteComponent;
    if (tweet.quoted_status) {
      QuoteComponent = <Quote data={tweet.quoted_status} />
    }

    return (
      <Card style={{ width: '100%'}} className="tweet-card border-0">
        <Card.Body className="border-0">
          <div>
            {retweetedUser}
            {this.renderTweetTop(retweet ? retweet : tweet)}
          </div>

          <div className="tweet-text">
            <Text data={retweet ? retweet : tweet} />
            </div>

            <div className="tweet-media">
              <Media data={retweet ? retweet : tweet}/>
            </div>
 
          {QuoteComponent}

          {this.renderTweetBottom(retweet ? retweet : tweet)}

          {post && <Countdown post={post}/> }
          </Card.Body>
      </Card>
    )
  }

  renderTweetTop(tweet: Tweet) {
    return (
      <div className="tweet-top">
        { this.renderUser(tweet.user) }
        <a href={`https://twitter.com/${tweet.user.screen_name}/status/${tweet.id_str}`} target="_blank"><TwitterLogo style={{width: 20}}/></a>
      </div>
    )
  }

  renderTweetBottom(tweet: Tweet) {
    let timestamp = formatDistanceToNow( new Date(tweet.created_at), 'h:m a · MMM d');
    let retweet_count = abbreviateNumber(tweet.retweet_count);
    let favorite_count = abbreviateNumber(tweet.favorite_count);

    return (
      <Card.Subtitle className="mr-auto mb-2 text-muted tweet-bottom">
        <div className="timestamp">
        <a href={`https://twitter.com/${tweet.user.screen_name}/status/${tweet.id_str}`} target="_blank" className="text-muted">
          {timestamp} ago
        </a>
        </div>
        <div className="tweet-actions">
          {/* <div className="action">
            <FontAwesomeIcon icon={faComment} size="1x" />
    </div> */}
          <div className="action" title="Retweet is coming soon to Brizzly">
            <FontAwesomeIcon icon={faRetweet} size="1x" /> {retweet_count}
          </div>
          <div className="action">
            <span onClick={this.like} className={ classnames({'liked': this.getTweet().favorited }) }>
              <FontAwesomeIcon icon={faHeart} size="1x" /> {favorite_count}
            </span>
          </div>
        </div>

      <div>
        {this.renderPostButtons()}
      </div>
      </Card.Subtitle>
    )
  }

  renderPostButtons() {
    let post = this.props.data.post;

    if (post) {
      return (
        <div className="post-actions">
          <Button variant="outline-secondary ml-n3" onClick={ this.edit }>
          {post.status === 'pending' ? 'Undo' : 'Redo'}
            <Countdown post={post} counter={true} />
          </Button>
          
          <Button className="ml-1 outline-secondary" onClick={ this.del }>
           <FontAwesomeIcon icon={faTrashAlt} size="1x"/>
          </Button>
        </div>
      )
    }
  }

  renderUser(user: Tweet['user']) {
    return (
      <div className="tweet-user">
        <img src={user.profile_image_url_https} />

        <div className="name-box">
          <div className="name">
            {user.name} { user.verified && <VerifiedIcon style={{width: 18}}/>} 
            <div className="screenName ml-2">{'@' + user.screen_name}</div>
          </div>
        </div>
      </div>
    )
  }

  getTweet() {
    if (this.props.originalTweet) {
      return this.props.originalTweet;
    }
    else {
      return this.props.data;
    }
  }

  /**
   * Like tweet
   */
  like = ()=> {
    let tweet = this.getTweet();

    // If already liked
    if (tweet.favorited) {
      TimelineActions.tweetUnlike(tweet.id_str)
      .catch(err => {
        this.props.showError(ALERTS.tweetUnlikeErr)
      })
    }
    else {
      TimelineActions.tweetLike(tweet.id_str)
      .catch(err => {
        this.props.showError(ALERTS.tweetLikeErr)
      })
    }
  }

  edit = ()=> {
    let post = this.props.data.post;
    this.props.editTweet(post.id)
  }

  del = ()=> {
    let post = this.props.data.post;
    this.props.deleteTweet(post.id)
  }
}
