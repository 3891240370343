import axios from 'axios';
import { firebaseApp } from '../firebase';
import { Tweet } from '../models/tweet';
import { Post } from '../models/post';
import TweetStore from '../stores/tweet';
import PostStore from '../stores/post';
import { PostActions } from '../actions/post';
import config from '../config';
import { API_PATH } from '../constants';

export class TimelineActions {

  public static loadPreview(url: string) {
    const apiUrl = `${config.API_URL}/brizzly/meta?url=${url}`;
    return axios.get(apiUrl)
  }

  /**
   * Load timeline
   */
  public static async loadTimeline(query?: {since_id?: string, max_id? : string}) {

    query = query || {};

    const loadTimeline = firebaseApp.functions().httpsCallable(`${API_PATH}/timeline`);
    return loadTimeline(query)
    .then((result) => {
      let data: { tweets: Array<Tweet>, pendingPosts: Array<Post> } = result.data;

      let { tweets, pendingPosts } = data;

      // Load data into stores
      TweetStore.load(tweets);
      PostStore.load(pendingPosts);
    })
  }

  /**
   * Load profile
   */
  public static async loadProfile(query?: {after_date? : string}) {

    query = query || {};

    const fn = firebaseApp.functions().httpsCallable(`${API_PATH}/profile`);

    return fn(query)
    .then((result) => {
      let data: { tweets: Array<Tweet>, pendingPosts: Array<Post> } = result.data;

      let { tweets, pendingPosts } = data;

      // Load data into stores
      TweetStore.load(tweets);
      PostStore.load(pendingPosts);

      return data;
    })
  }

  /**
   * Get post for edit
   * 
   * - When starting the edit process for a tweet
   * - check if it's a pending post or already posted
   * - Then check if in the cache or we need to load it
   */
  static getPostForEdit(postId: string) {
    let tweetPost = TweetStore.getByPostId(postId);
    let post = PostStore.getById(postId);

    // If already posted
    if (tweetPost) {
      return tweetPost;
    }
    else if (post) {
      // PostActions.startEdit(postId, false);
      return post;
    }
    else {
      // PostActions.startEdit(postId, true);
      return null;
    }
  }

  /**
   * Tweet like
   */
  static tweetLike(id: string) {
    const fn = firebaseApp.functions().httpsCallable(`${API_PATH}/like`);
    return fn({id})
    .then((result) => {
      let tweet: Tweet = result.data;
      TweetStore.add(tweet);
    })
  }

  /**
   * Tweet unlike
   */
  static tweetUnlike(id: string) {
    const fn = firebaseApp.functions().httpsCallable(`${API_PATH}/like/del`);
    return fn({id})
    .then((result) => {
      let tweet: Tweet = result.data;
      TweetStore.add(tweet);
    })
  }
}